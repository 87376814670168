:root {
  --main-bg-color: #2ea359;
  --content-spacing: 20px;
  --text-color: #ffffff;
  --text-color1: #202020;
}

.container1 {
  width: 100%;
  padding: var(--content-spacing);
}

.row {
  margin-left: -var(--content-spacing);
  margin-right: -var(--content-spacing);
}

.col-md-4 {
  padding-left: var(--content-spacing);
  padding-right: var(--content-spacing);
}

.cta-section {
  background-color: var(--main-bg-color);
  color: var(--text-color);
}

.btn-outline-primary {
  border-color: var(--main-bg-color);
  color: var( --text-color);
  background-color: transparent; /* Ensure transparent background */
}

.btn-outline-primary:hover {
  background-color: var(--text-color1);
  color: var(--text-color1);
}

.btn-light {
  background-color:green;
  color: var( --text-color);
}

.btn-light:hover {
  background-color: var(--main-bg-color);
  color: #ffffff; /* Change text color to white on hover */
}

.feature-showcase {
  padding: 50px 0;
  background-color: #f9f9fa;
}

.feature-box {
  text-align: center;
  margin-bottom: 40px;
}

.feature-box i {
  font-size: 40px;
  color: #2ea359; /* Updated icon color to #2ea359 */
  margin-bottom: 10px;
}

.feature-box h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.feature-box p {
  font-size: 16px;
  color: #555;
}

.impact-statistics {
  padding: 40px 0;
  background-color: #e9ecef; /* Light background for contrast */
}

.impact-statistics h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.impact-statistics h3 {
 
  color: #2ea359; /* Match the icon color */
}

.impact-statistics p {
  font-size: 20px;
  color: #555;
}
.hover-card {
  transition: transform 0.2s, box-shadow 0.2s;
}

.hover-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.card {
  display: flex;
  flex-direction: column;
  height: 100%; /* Makes sure the card takes the full height of the column */
}

.card-img-top {
  height: 300px; /* Set a fixed height for images */
  object-fit: cover; /* Ensures the image covers the area */
}

.card-body {
  flex-grow: 1; /* Allows the card body to grow and fill the space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out elements inside the card */
}
.cta-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}


.btn-outline-dark {
  border-radius: 25px;
  font-size: 1.1rem; /* Adjust button font size */
}