.contact-section {
    position: relative;
    height: auto; /* Adjust based on your needs */
}

.map-container {
    position: relative;
    width: 100%;
    height: 600px; /* Adjust as needed */
    overflow: hidden;
}

.map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    filter: brightness(1); /* Adjust brightness to enhance clarity */
}

.overlay {
    position: relative;
    z-index: 1; /* Content in front of the map */
    background: 'transparent ';
    padding: 50px 0; /* Space around the content */
}

.bg-white {
    opacity: 0.9; /* Slight transparency for the contact form */
}

h2, h3 {
    color: #333; /* Adjust heading colors as needed */
}
