.beles-tech-navbar {
  background-color: #ffffff; /* Example color */
  position: fixed; /* Always stick to the top */
  top: 0; /* Stick to the top */
  width: 100%; /* Full width */
  z-index: 1000; /* Above other elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 

}

.beles-tech-navbar .brand {
  color: #060606; /* Brand text color */
  font-size: 1.5rem;
}

.beles-tech-navbar .nav-link {
  color: #060606; /* Text color for nav links */
  margin: 0 15px;
  font-weight: normal; /* Normal weight for links */
  position: relative; /* For underline effect */
}

.beles-tech-navbar .nav-link:hover {
  color: #2ea359; /* Highlight color on hover */
  font-weight: bold; /* Make font bold on hover */
}

.beles-tech-navbar .nav-link:hover::after {
  content: ""; /* Add underline effect */
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px; /* Adjust based on your preference */
  height: 2px; /* Thickness of the underline */
  background-color: #2ea359; /* Underline color matching the hover */
}
.beles-tech-navbar .nav-link.active::after{
  color: #2ea359; /* Highlight color on hover */
  font-weight: bold; /* Make font bold on hover */
  content: ""; /* Add underline effect */
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px; /* Adjust based on your preference */
  height: 2px; /* Thickness of the underline */
  background-color: #2ea359; /* Underline color matching the hover */
}
.beles-tech-navbar .nav-link.active{
  color: #2ea359; /* Highlight color on hover */
  font-weight: bold; /* Make font bold on hover */
 
}

.logo {
  height: 100px; /* Logo height */
  object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .beles-tech-navbar .nav-link {
    margin: 0 10px; /* Reduce margin for mobile */
  }
  
  .logo {
    height: 70px; /* Reduce logo height on smaller screens */
  }
}

body {
  padding-top: 90px; /* Adjust this based on your navbar height */
}
