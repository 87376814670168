.footernav
{
    font-weight: 400 !important;
    font-size: 16px;
    transition: ease-in 0.2s;
    color:black;
    margin-bottom: 10px;
    
    
}
.footernav:hover{
    color: #2ea359; /* Highlight color on hover */
    font-weight: 700;
    padding: 5px;
    border-bottom: 5px solid #2ea359;

    
}

