.homepage-hero {
    background-color: #f8f9fa;
    padding: 5rem 0;
}

.homepage-hero-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.homepage-hero-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.homepage-hero-image {
    width: 100%;
    height: auto;
    max-height: 500px;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
}

.homepage-hero-image:hover {
    transform: scale(1.05);
}

.homepage-get-started-button {
    color: white;
    background-color: #4bb873;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.homepage-get-started-button:hover {
    background-color: rgb(121, 194, 121);
}

@media (max-width: 767.98px) {
    .homepage-hero-title {
        font-size: 2.5rem;
    }

    .homepage-hero-description {
        font-size: 1rem;
    }

    .homepage-hero-image {
        margin: 2rem auto 0;
    }
}

.homepage-hero-text {
    text-align: center;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: -20px;
}

.homepage-hero-text h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.homepage-hero-text p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .homepage-hero-text h1 {
        font-size: 1.8rem;
    }
    .homepage-hero-text p {
        font-size: 1rem;
    }
}

.mission-highlight-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
