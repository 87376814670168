.bg-white {
    background-color: #28a745; /* Bootstrap green */
     /* Optional: Change text color for better contrast */
  }
  
  .card {
    border: none; /* Optional: Remove any borders */
  }
  .shadows{
    background-color: #28a745;
  }
  /* Additional styles to improve appearance */
  .cta{
    color: black;
  }
  .cta-button {
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: rgba(255, 255, 255, 0.8); /* Lighter background on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(8, 8, 8, 0.5); /* Glow effect */
  }
  h3 {
    border-bottom: 2px solid #28a745;
   
  }
  
  a {
    color:#28a745; 
    transition: color 0.3s;
  }
  .full-width-container {
    width: 100%;
    padding: 0 15px; /* Adjust padding as necessary */
  }
  a:hover {
    color:#0b1f0f; /* Darker shade on hover */
  }
  
h1, h2, h5 {
  margin-bottom: 1rem;
}


.border {
  transition: transform 0.2s;
}
.card img {
  height: 150px; /* Adjust this value as needed */
  object-fit: cover;
}
.border:hover {
  transform: scale(1.02);
}

.facility-card {
  text-align: center; /* Center the text in the card */
}

.facility-image {
  height: 600px; /* Set a fixed height */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  width: 80%; /* Ensure the image takes full width of the column */
  border-radius: 8px; /* Optional: rounded corners */
}
.facility-card {
  background-color: #ffffff; /* Optional: set background color */
  border: 1px solid #ddd; /* Optional: add a border */
  border-radius: 5px; /* Optional: round the corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add shadow */
  transition: transform 0.2s; /* Optional: add transition for hover effect */
}

.facility-card:hover {
  transform: scale(1.02); /* Optional: scale effect on hover */
}

