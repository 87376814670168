/* Event.css */

@media (max-width: 668px) {
    .text-container {
        padding: 0.5rem; /* Adjusted padding for mobile */
        height: auto; /* Allow height to adjust based on content */
        display: flex; /* Use flexbox for horizontal alignment */
        flex-direction: column; /* Align children in a column */
        align-items: center; /* Center items vertically */
        justify-content: center; /* Center items horizontally */
        text-align: center; /* Center text */
        gap: 0.2rem; /* Add space between items */
        margin-bottom: 1rem; /* Add space between the containers */
        position: absolute; /* Positioning the container */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        transform: translate(-30%, -30%); /* Adjust to perfectly center */
        width: 80%; /* Optional: adjust width as needed */
    }

    .text-container h2,
    .text-container h3,
    .text-container p {
        margin: 0; /* Remove default margins */
    }

    .upcoming-event-image,
    .past-event-image {
        height: 40px; /* Adjusted image height for mobile */
        object-fit: cover; /* Ensure the image scales properly */
        position: relative; /* Ensure the image container is positioned */
    }

    .btn {
        width: 100%; /* Full-width buttons for better touch targets */
    }

    .container-fluid {
        padding: 0 0.5rem; /* Padding for better responsiveness */
        height: auto; /* Allow height to adjust based on content */
    }

    .header-title {
        font-size: 1.5rem; /* Smaller font for titles */
    }

    .lead {
        font-size: 1rem; /* Adjust lead font size */
    }

    .text-center h1,
    .text-center h2 {
        font-size: 1.5rem; /* Adjusted font sizes for headings */
    }
}