.image-12 {
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.full-width {
    width: 100%;
    padding: 15px;
    background-color: #f8f9fa;
}
.team-member-image {
    width: 100%;
    height: auto; /* Adjusts height to maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the container without cropping */
  }

  .unique-container {
   /* Adjust width as needed */
    margin: auto;
  }
  
  .unique-card {
    border: 1px solid #ccc; /* Optional: Add a border for better visibility */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensures the image fits within the card */
    height: 100%; /* Makes all cards the same height */
    display: flex;
    min-width: 300px;
    flex-direction: column;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  }
  
  
  
  .unique-structure-image {
    width: 100%; /* Ensures the image takes full width */
    height: auto; /* Maintain aspect ratio */
    max-height: 500px; /* Adjust height for organizational chart */
  }
  
  .card-body {
    padding: 0px; /* Adjust padding as needed */
  }
  
  
.hero-section {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    flex-direction: column; /* Stack elements vertically */
    padding: 100px 0; /* Adjust top/bottom padding */
}

.btn-square {
    display: inline-block;
    padding: 20px 50px;
    background-color: #2ea359; /* Green background */
    color: white;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
    cursor: default;
    transition: background-color 0.3s;
    margin-top: 0; /* Set margin-top to 0 to move it to the top */
    margin-bottom: 10px; /* Optional: Add margin-bottom for spacing */
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
    .hero-section {
        padding: 50px 20px; /* Adjust padding for smaller screens */
    }
    
    .btn-square {
        padding: 15px 30px; /* Adjust padding for smaller screens */
        font-size: 14px; /* Reduce font size */
        width: 100%; /* Make button full width */
        box-sizing: border-box; /* Ensure padding is included in the width */
    }
}

.bg {
    background-color: #2ea359;
}

.logo-image {
    max-width: 200px; /* Adjust as needed for the desired size */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the logo */
    display: block; /* Ensure the logo behaves like a block element */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .btn-square {
        margin: 20px auto; /* Center button on mobile */
        display: block; /* Ensure button takes full width */
    }

    .hero-section {
        padding: 50px 20px; /* Adjust padding for mobile */
    }

    .team-member-image {
        height: auto; /* Allow flexible height on mobile */
    }
}


.btn-square {
    margin-left: 60px; /* Default margin for larger screens */
}

/* General button style */
.btn {
    background-color: #2ea359; /* Green button color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #28a745; /* Darker green on hover */
}
.card {
    width: 100%; /* Ensure the card takes full width of the column */
    max-width: 300px; /* Set a maximum width for uniformity */
    margin: 0 auto; /* Center the card */
}


.integrity-section {
    --card-bg-color: white; /* Set background color to green */
    --card-border-radius: 8px; /* Rounded corners */
    --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    --card-margin-bottom: 10px; /* Space between sections */
    --image-height: 450px; /* Fixed height for images */
  
    .row {
      display: flex;
      align-items: stretch; /* Ensure all columns stretch to the same height */
      margin-bottom: var(--card-margin-bottom);
    }
  
    .content-wrapper {
      background: var(--card-bg-color); /* Use the green background */
      border-radius: var(--card-border-radius);
      box-shadow: var(--card-shadow);
      display: flex;
      flex-direction: column;
      justify-content: space-between; /* Ensure consistent spacing within cards */
      height: 100%; /* Allow card to fill the height */
      overflow: hidden; /* Prevent overflow */
      flex: 1; /* Make sure content takes equal space in the flex container */
    }
  
    .img-fluid {
      height: var(--image-height);
      width: 900%; /* Ensure image takes full width */
      object-fit: cover; /* Cover designated area without distortion */
      border-radius: var(--card-border-radius); /* Match image corners to card corners */
    }
  
    .btn-primary {
      margin-top: 15px; /* Space above button */
    }
  
    @media (max-width: 768px) {
      .row {
        flex-direction: column; /* Stack items vertically on mobile */
        margin-bottom: 15px; /* Reduce spacing for mobile */
      }
  
      .content-wrapper {
        margin: 0; /* Reset margin for mobile */
      }
      
      .img-fluid {
        height: auto; /* Allow images to resize on mobile */
      }
    }
  }
  .container1 {
    width: 100%;
    padding: var(--content-spacing);
  }